import {HiCheckBadge} from '@react-icons/all-files/hi2/HiCheckBadge';
import {HiOutlineArrowLongRight} from '@react-icons/all-files/hi2/HiOutlineArrowLongRight';
import {graphql, navigate} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import Seo from '../../components/Seo/Seo';
import BoxWrapper from '../../components/Wrapper/BoxWrapper';
import CardWrapper from '../../components/Wrapper/CardWrapper';
import Button from '../../components/_baseComponents/Button/Button';
import SectionHeading from '../../components/_baseComponents/SectionHeading/SectionHeading';
import useCheckBreakpoint from '../../hooks/useCheckBreakpoint';

const WebAppSolutionPage = () => {
  const {isDesktop, isTablet, isMobile} = useCheckBreakpoint();

  return (
    <div className="flex flex-col gap-20 pt-[70px] lg:gap-24 lg:pt-20">
      <BoxWrapper className="mt-6 justify-center md:mt-8 lg:mt-12">
        <div className="flex w-full flex-col-reverse items-center justify-center gap-2 overflow-hidden rounded-xl bg-white/70 p-3 md:flex-row lg:gap-12 lg:rounded-[2.5rem]">
          {/* Bg effect */}
          <div className="relative before:absolute before:-z-10 before:block before:h-[512px] before:w-[512px] before:rounded-full before:bg-cyan-500/60 before:blur-[9rem] before:will-change-[filter] md:before:-left-[15vw] md:before:bottom-[0rem]" />

          <div className="flex flex-col gap-5 self-start md:self-center">
            <div className="flex flex-row gap-2 text-3xl font-bolder text-gray-900 lg:text-5xl">
              Lập trình Web App
            </div>
            <div className="lg:text-xl">
              Đơn giản hoá - tự động hoá quy trình kinh doanh - quản lý cho
              doanh nghiệp
            </div>
            <div className="flex flex-col items-start gap-4  lg:flex-row">
              <Button
                type="primary"
                content="Tư vấn báo giá"
                Icon={ButtonIcon}
                className="flex justify-center gap-2 rounded px-6 py-2 font-medium md:flex-none lg:flex-none"
                onClick={() => {
                  navigate('/lien-he');
                }}
              />
              <Button
                type="outline"
                content="Xem Demo"
                Icon={ButtonIcon}
                className="flex justify-center gap-2 rounded px-6 py-2 font-medium md:flex-none lg:flex-none"
              />
            </div>
          </div>
          {/* Bg effect */}
          <div className="relative before:absolute before:-bottom-[30rem] before:-left-[50vw] before:-z-10 before:block before:h-[512px] before:w-[512px] before:rounded-full before:bg-violet-500/80 before:blur-[9rem] before:will-change-[filter]" />
          <div>
            <StaticImage
              src="../../assets/services/webapp/man-study-code.png"
              alt="Customer avatar"
              width={750}
              className="w-[300px] md:w-[350px] lg:w-[450px]"
              objectFit="contain"
            />
          </div>
          {/* Bg effect */}
          <div className="relative before:absolute before:-bottom-[25rem] before:-left-[15vw] before:-z-10 before:block before:h-[512px] before:w-[512px] before:rounded-full before:bg-purple-400/80 before:blur-[9rem] before:will-change-[filter]" />
        </div>
      </BoxWrapper>
      <BoxWrapper className={`flex flex-col`}>
        <SectionHeading
          content="Tai sao cần thiết kế Web App riêng cho doanh nghiệp"
          className="w-full items-center"
          contentClassName="flex lg:w-[600px] flex-row text-center text-2xl md:text-3xl lg:text-4xl"
        />

        <div className="flex flex-col gap-10 md:gap-8 lg:flex-none">
          <div className="flex w-full flex-col items-center justify-center gap-2 md:flex-row md:gap-8 lg:gap-5 xl:gap-10">
            <div className="">
              <StaticImage
                src="../../assets/home/project-thumbup-man.png"
                alt="project-thumbup-man"
                width={800}
                className="w-[300px] md:w-[380px] lg:w-[450px]"
              />
            </div>
            {/* Bg effect */}
            <div className="relative before:absolute before:-bottom-[40rem] before:-right-[40vw] before:-z-10 before:block before:h-[512px] before:w-[512px] before:rounded-full before:bg-green-500/20 before:blur-[9rem] before:will-change-[filter] before:md:-top-[30rem] before:md:bg-green-500/30 before:md:blur-[20rem]  before:lg:-left-[30vw] before:lg:-top-[13rem]  before:lg:bg-blue-400/30 before:lg:blur-[8rem]" />

            <div className="flex flex-col gap-5  lg:relative lg:-left-4 lg:w-[520px] lg:items-start">
              <h3 className="text-xl font-bolder lg:text-2xl">
                Thay thế các công việc quản lý thủ công kém hiệu quả
              </h3>
              <div className="flex w-full flex-col items-start gap-4 md:text-sm lg:text-base">
                <div className="flex flex-row gap-2">
                  <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                  <div>
                    Quản lý bằng excel và các công cụ khác đã không còn đáp ứng
                    khi quy mô ngày càng mở rộng
                  </div>
                </div>
                <div className="flex flex-row gap-2">
                  <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                  <div>
                    Tiết kiệm thời gian, tiết kiệm nguồn lực và ngân sách với hệ
                    thống đồng bộ, dễ sử dụng
                  </div>
                </div>
                <div className="flex flex-row gap-2">
                  <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                  <div>
                    Đòi hỏi cao hơn về hiệu suất làm việc và mọi số liệu là rõ
                    ràng
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col-reverse items-center justify-center gap-2 md:flex-row md:gap-14 lg:gap-20 ">
            <div className="flex flex-col items-start gap-5 lg:w-[520px]">
              <h3 className="text-xl font-bolder lg:text-2xl">
                Bảo mật cao và không phụ thuộc vào bên thứ 3
              </h3>
              <div className="flex w-full flex-col items-start gap-4 md:text-sm lg:text-base">
                <div className="flex flex-row gap-2">
                  <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                  <div>
                    Server của riêng bạn, Hệ thống hoàn toàn là của bạn và đồng
                    bộ với Website - App di động mà không phụ thuộc vào 1 bên
                    thứ 3 cung cấp phần mềm
                  </div>
                </div>
                <div className="flex flex-row gap-2">
                  <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                  <div>
                    Không lo mất thông tin, lộ thông tin khách hàng như khi sử
                    dụng trên nền tảng phần mềm của nhà cung cấp
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <StaticImage
                src="../../assets/home/project-checkphone-man.png"
                alt="checkphone-man"
                width={800}
                className="w-[300px] md:w-[380px] lg:w-[450px]"
              />
            </div>
          </div>

          <div className="flex w-full flex-col items-center justify-center gap-2 md:flex-row md:gap-7 lg:gap-5 xl:gap-10">
            <div className="">
              <StaticImage
                src="../../assets/home/project-presentation-man.png"
                alt="presentation-man"
                width={800}
                className="w-[300px] md:w-[380px] lg:w-[450px]"
              />
            </div>
            {/* Bg effect */}
            <div className="relative before:absolute before:-top-[100rem] before:left-0 before:-z-10 before:block before:h-[640px] before:w-[640px] before:rounded-full before:bg-cyan-400/20 before:blur-[9rem] before:will-change-[filter] before:md:-top-[15rem] before:md:bg-cyan-400/30 before:md:blur-[20rem] before:lg:-right-[15vw]   before:lg:-top-[70rem] before:lg:bg-orange-300/20 before:lg:blur-[8rem]" />

            <div className="flex flex-col gap-5 lg:relative lg:-left-4 lg:w-[520px]">
              <h3 className="text-xl font-bolder lg:text-2xl">
                Tính tương tác cao - và sự đồng bộ
              </h3>
              <div className="flex w-full flex-col items-start gap-4 md:text-sm lg:text-base">
                <div className="flex flex-row gap-2">
                  <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                  <div>
                    Người dùng có thể dễ dàng tương tác, thực hiện các thao tác
                    - yêu cầu đặc biệt trên website của bạn như: đặt hàng, đặt
                    phòng, thanh toán, quản lý đăng nhập, làm thành viên, thực
                    hiện 1 công việc trên website (rao vặt, đăng tin)...
                  </div>
                </div>
                <div className="flex flex-row gap-2">
                  <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                  <div>
                    Sự đồng bộ giữa website và Web App cho phép người chủ quản
                    lý có thể tương tác với người dùng, có thể sao lưu dữ liệu 1
                    cách thống nhất, hoàn chỉnh và dễ dàng hơn rất nhiều trong
                    khâu quản lý
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BoxWrapper>

      <BoxWrapper className={``}>
        <div className="flex flex-col justify-center gap-2 overflow-hidden rounded-xl bg-white/70 p-3 lg:flex-row lg:gap-4 lg:rounded-[2.5rem] lg:py-8 xl:gap-8">
          {/* Bg effect */}
          <div className="relative before:absolute before:-left-[2rem] before:bottom-[10rem] before:-z-10 before:block before:h-[800px] before:w-[800px] before:rounded-full before:bg-cyan-500/60 before:blur-[8rem] before:will-change-[filter]" />
          <SectionHeading
            content="Nâng cao hiệu suất công việc - quản lý"
            className="w-full items-start md:items-center lg:items-start"
            contentClassName="flex lg:w-[340px] flex-row text-2xl md:text-3xl lg:text-4xl text-gray-900"
          />
          {/* Bg effect */}
          <div className="relative before:absolute before:-bottom-[25rem] before:-left-[25vw] before:-z-10 before:block before:h-[800px] before:w-[800px] before:rounded-full before:bg-violet-500/80 before:blur-[9rem]" />
          <div className="flex flex-row flex-wrap justify-center gap-4 text-gray-900 lg:gap-8">
            <CardWrapper
              className="flex flex-col gap-2 rounded-lg bg-white/40 p-5 md:w-[47%] lg:rounded-xl"
              isShadow={false}
            >
              <StaticImage
                src="../../assets/services/webapp/check-money.png"
                alt="checkphone-man"
                width={128}
                height={128}
                className="h-16 w-16"
                objectFit="cover"
              />
              <div className="flex flex-col gap-3">
                <h3 className="text-lg font-bolder">
                  Hiệu quả cho chủ doanh nghiệp
                </h3>
                <p className="text-gray-500">
                  Tạo ra nền tảng cho khách hàng sử dụng, mua hàng,... và kinh
                  doanh, quản lý hiệu quả - tối ưu ngân sách, dễ dàng bao quát
                  kinh doanh với báo cáo chi tiết.
                </p>
              </div>
            </CardWrapper>
            <CardWrapper
              className="flex flex-col gap-2 rounded-lg bg-white/40 p-5 md:w-[47%] lg:rounded-xl"
              isShadow={false}
            >
              <StaticImage
                src="../../assets/services/webapp/check-pen.png"
                alt="checkphone-man"
                width={128}
                height={128}
                className="h-16 w-16"
                objectFit="cover"
              />
              <div className="flex flex-col gap-3">
                <h3 className="text-lg font-bolder">Hiệu quả cho nhân viên</h3>
                <p className="text-gray-500">
                  Các số liệu là đồng bộ và nhân viên có thể thay bạn làm nhiều
                  hơn nữa các công việc - các thông tin và thực hiện công việc
                  của họ trơn tru, nhanh chóng.
                </p>
              </div>
            </CardWrapper>
            <CardWrapper
              className="flex flex-col gap-2 rounded-lg bg-white/40 p-5 md:w-[47%] lg:rounded-xl"
              isShadow={false}
            >
              <StaticImage
                src="../../assets/services/webapp/money-up.png"
                alt="checkphone-man"
                width={128}
                height={128}
                className="h-16 w-16"
                objectFit="cover"
              />
              <div className="flex flex-col gap-3">
                <h3 className="text-lg font-bolder">Hiệu quả cho khách hàng</h3>
                <p className="text-gray-500">
                  Người dùng có thể thao tác, thực hiện công việc ngay trên
                  website/ứng dụng website, trở thành thành viên và thực hiện
                  các thao tác mà họ có quyền.
                </p>
              </div>
            </CardWrapper>
            <CardWrapper
              className="flex flex-col gap-2 rounded-lg bg-white/40 p-5 md:w-[47%] lg:rounded-xl"
              isShadow={false}
            >
              <StaticImage
                src="../../assets/services/webapp/blue-box.png"
                alt="checkphone-man"
                width={128}
                height={128}
                className="h-16 w-16"
                objectFit="cover"
              />
              <div className="flex flex-col gap-3">
                <h3 className="text-lg font-bolder">Quản lý mọi lúc mọi nơi</h3>
                <p className="text-gray-500">
                  Bạn, nhân viên và khách hàng có thể sử dụng đa thiết bị máy
                  tính, điện thoại, tablet,...; đa trình duyệt; đa hệ điều hành.
                </p>
              </div>
            </CardWrapper>
          </div>
          {/* Bg effect */}
          <div className="relative before:absolute before:-bottom-[15rem] before:-left-[15vw] before:-z-10 before:block before:h-[800px] before:w-[800px] before:rounded-full before:bg-purple-400/80 before:blur-[9rem]" />
        </div>
      </BoxWrapper>
      <BoxWrapper className={`flex flex-col gap-2 lg:gap-4`}>
        <SectionHeading
          content="Kinh nghiệm lập trình WebApp đa lĩnh vực"
          className="w-full items-center"
          contentClassName="flex flex-row text-2xl md:text-3xl lg:text-4xl text-gray-900"
        />
        {/* Bg effect */}
        <div className="relative before:absolute before:-bottom-[25rem] before:-left-[15vw] before:-z-10 before:block before:h-[800px] before:w-[800px] before:rounded-full before:bg-green-400/20 before:blur-[9rem]" />

        <div className="flex flex-row flex-wrap justify-center gap-2 text-center text-base font-bolder md:gap-4 lg:gap-8 lg:text-lg">
          <CardWrapper
            className="flex w-[48%] flex-col items-center justify-center gap-3 border border-white bg-white/40 p-4 lg:w-[22%]"
            isShadow={false}
          >
            <StaticImage
              src="../../assets/services/webapp/manage-delivery.png"
              alt="checkphone-man"
              width={250}
              height={250}
              className="w-20 md:w-28"
              objectFit="cover"
            />
            <div className="">Quản lý vận chuyển</div>
          </CardWrapper>
          <CardWrapper
            className="flex w-[48%] flex-col items-center justify-center gap-3 border border-white bg-white/40 p-4 lg:w-[22%]"
            isShadow={false}
          >
            <StaticImage
              src="../../assets/services/webapp/manage-education.png"
              alt="checkphone-man"
              width={250}
              height={250}
              className="w-20 md:w-28"
              objectFit="cover"
            />
            <div className="">Quản lý giáo dục</div>
          </CardWrapper>
          <CardWrapper
            className="flex w-[48%] flex-col items-center justify-center gap-3 border border-white bg-white/40 p-4 lg:w-[22%]"
            isShadow={false}
          >
            <StaticImage
              src="../../assets/services/webapp/manage-HMR.png"
              alt="checkphone-man"
              width={250}
              height={250}
              className="w-20 md:w-28"
              objectFit="cover"
            />
            <div className="">Quản lý nhân sự HRM</div>
          </CardWrapper>
          <CardWrapper
            className="flex w-[48%] flex-col items-center justify-center gap-3 border border-white bg-white/40 p-4 lg:w-[22%]"
            isShadow={false}
          >
            <StaticImage
              src="../../assets/services/webapp/manage-sale.png"
              alt="checkphone-man"
              width={250}
              height={250}
              className="w-20 md:w-28"
              objectFit="cover"
            />
            <div className="">Quản lý bán hàng</div>
          </CardWrapper>
          <CardWrapper
            className="flex w-[48%] flex-col items-center justify-center gap-3 border border-white bg-white/40 p-4 lg:w-[22%]"
            isShadow={false}
          >
            <StaticImage
              src="../../assets/services/webapp/manage-travel.png"
              alt="checkphone-man"
              width={250}
              height={250}
              className="w-20 md:w-28"
              objectFit="cover"
            />
            <div className="">Quản lý du lịch</div>
          </CardWrapper>
          <CardWrapper
            className="flex w-[48%] flex-col items-center justify-center gap-3 border border-white bg-white/40 p-4 lg:w-[22%]"
            isShadow={false}
          >
            <StaticImage
              src="../../assets/services/webapp/manage-business.png"
              alt="checkphone-man"
              width={250}
              height={250}
              className="w-20 md:w-28"
              objectFit="cover"
            />
            <div className="">Quản lý doanh nghiệp</div>
          </CardWrapper>
          <CardWrapper
            className="flex w-[48%] flex-col items-center justify-center gap-3 border border-white bg-white/40 p-4 lg:w-[22%]"
            isShadow={false}
          >
            <StaticImage
              src="../../assets/services/webapp/manage-customer.png"
              alt="checkphone-man"
              width={250}
              height={250}
              className="w-20 md:w-28"
              objectFit="cover"
            />
            <div className="">Quản lý khách hàng</div>
          </CardWrapper>
          <CardWrapper
            className="flex w-[48%] flex-col items-center justify-center gap-3 border border-white bg-white/40 p-4 lg:w-[22%]"
            isShadow={false}
          >
            <StaticImage
              src="../../assets/services/webapp/manage-inventory.png"
              alt="checkphone-man"
              width={250}
              height={250}
              className="w-20 md:w-28"
              objectFit="cover"
            />
            <div className="">Quản lý kho bãi</div>
          </CardWrapper>
        </div>
        {/* Bg effect */}
        <div className="relative before:absolute before:-bottom-[15rem] before:-right-[15vw] before:-z-10 before:block before:h-[800px] before:w-[800px] before:rounded-full before:bg-cyan-400/20 before:blur-[9rem]" />
      </BoxWrapper>
    </div>
  );
};

const ButtonIcon = () => <HiOutlineArrowLongRight className="h-6 w-6" />;

export default WebAppSolutionPage;

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({data: {wpPage}}) => {
  return (
    <Seo
      yoastSeo={wpPage?.seo}
      title={wpPage?.title}
      uri={wpPage?.uri}
      description={wpPage?.content && wpPage.content.substring(150)}
    />
  );
};

export const pageQuery = graphql`
  query {
    wpPage(uri: {eq: "/dich-vu/lap-trinh-web-app"}) {
      id
      uri
      title
      content
      seo {
        fullHead
        canonical
      }
    }
  }
`;
